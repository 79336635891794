import * as React from 'react';
import { IBgImageStripProps } from '../BgImageStrip.types';
import { BgImageStripSkinMap } from './skinMap';

const BgImageStrip: React.FC<IBgImageStripProps> = props => {
  const { skin, backgroundImage, id } = props;
  const BgImageStripClass = BgImageStripSkinMap[skin];
  return (
    <BgImageStripClass
      id={id}
      skin={skin}
      backgroundImage={backgroundImage}
    ></BgImageStripClass>
  );
};

export default BgImageStrip;
